import firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyBOTyAx7E3w213UI2xF6RidLnv-39quhOM',
  authDomain: 'codex-rey-david.firebaseapp.com',
  projectId: 'codex-rey-david',
  storageBucket: 'codex-rey-david.appspot.com',
  messagingSenderId: '152940223102',
  appId: '1:152940223102:web:5192faf3ae84f0f4c9d62f',
  measurementId: 'G-8YFETGRPVG',
};

firebase.initializeApp( config );

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const timeStamp = firebase.firestore.Timestamp;
export type TimeStamp = firebase.firestore.Timestamp;
export const fieldValue = firebase.firestore.FieldValue;
export type FieldValue = firebase.firestore.FieldValue;
export const authPersistenceLocal = firebase.auth.Auth.Persistence.LOCAL;
export const authPersistenceSession = firebase.auth.Auth.Persistence.SESSION;
export const emailAuthProvider = firebase.auth.EmailAuthProvider;
